import React from "react"

import Layoutcs from "../../components/layoutcs/layoutcs"
import SEO from "../../components/seo"

import Container from 'react-bootstrap/Container'

import Bloglist from "../../components/bloglist/bloglist"

import "../index.scss"
import "../blog.scss"

const Blog = () => (

	<Layoutcs>
		<SEO title="Blog" description="xxx description" />

		<div className="ftrs-background ftrs-background--about-us" data-sal="fade" data-sal-duration="2000"></div>

		<Container className="ftrs-content pb-4 ftrs-blog">

			<h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Future blog</h1>

			<Bloglist></Bloglist>

		</Container>

	</Layoutcs>

)

export default Blog